  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Poppins', sans-serif !important;
  background-color: #FFFAFB;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;

}

::-webkit-scrollbar {
  width: 0px;
}

.right-arrow,
.left-arrow {
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f00;
  font-size: 25px;
  border-radius: 4px;
  position: absolute;
  bottom: -20px;
  right: 80px;
  transform: scale(1, 1);
  transition: 0.3s all ease-in-out;
}

.right-arrow {
  right: 140px;
}

.right-arrow:hover,
.left-arrow:hover {
  transform: scale(1.3, 1.3);
}

.react-horizontal-scrolling-menu--wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.detail-image {
  width: 729px;
  height: 742px;
}

.hero-banner-img {
  position: absolute;
  right: 40px;
  top: 0px;
  width: 700px;
  height: 900px;
  margin-top: -330px;

}

.exercise-card {
  width: 400px;
  height: 445px;
  background: #fff;
  border-top: 4px solid #f00;
  border-bottom-left-radius: 20px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 10px;
  transform: scale(1, 1);
  transition: 0.3s all ease-in-out;
}

.exercise-card img {
  height: 326px;
}

.bodyPart-card {
  transform: scale(1, 1);
  transition: 0.3s all ease-in-out;
}

.exercise-card:hover,
.bodyPart-card:hover {
  transform: scale(1.1, 1.1);
}

.search-btn:hover {
  color: #f00 !important;
  border: 1px solid #f00 !important;
}

.exercise-video {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 387px;
  height: 381px;
  text-decoration: none;
}
.bgVideo{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.gradient{
  background: rgba(0,0,0,0.5);  /* fallback for old browsers */
background: -webkit-linear-gradient(to bottom, #000, transparent);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to bottom, #000, transparent); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.gradient-text {
    font-size: 72px;
    background: rgb(0,0,0, 0.7);
    background: linear-gradient(279deg, rgba(255,255,186,0.6867121848739496) 0%, rgba(121,120,9,0.835171568627451) 51%, rgba(255,239,0,0.9023984593837535) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}
@media screen and (max-width:1200px) {
  .detail-image {
    width: 300px;
    height: 300px;
  }


  .react-horizontal-scrolling-menu--scroll-container {
    width: 500px;
  }

  .left-arrow,
  .right-arrow {
    position: static !important;
  }

  .hero-banner-img {
    display: none;
  }

  .exercise-card {
    width: 320px;
  }

  .exercise-video {
    width: 320px;
    height: 300px;
  }

}

@media screen and (max-width:400px) {
  .exercise-card {
    width: 280px;
  }
}
@media (min-width: 992px) {
  body {
    padding: 0px 70px;
  }
}